<template>
  <div class="ActivityResults">
    <div class="ActivityResults--Header">
        <div class="ActivityResults--Downloads">
          <div v-on:click="fetchActivityExcel()">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.37019 0.6531C9.24906 0.553225 9.08862 0.511787 8.9335 0.540475L0.4335 2.13423C0.181687 2.18097 0 2.39985 0 2.65591V14.3434C0 14.5984 0.181687 14.8183 0.4335 14.8651L8.9335 16.4588C8.96537 16.4652 8.99938 16.4684 9.03125 16.4684C9.15344 16.4684 9.27456 16.4259 9.37019 16.3462C9.49238 16.2453 9.5625 16.0944 9.5625 15.9372V1.06216C9.5625 0.90385 9.49238 0.754037 9.37019 0.6531ZM8.5 15.2965L1.0625 13.9025V3.09685L8.5 1.70285V15.2965Z" fill="#939393"/>
              <path d="M16.4688 2.65591H9.03125C8.738 2.65591 8.5 2.89391 8.5 3.18716C8.5 3.48041 8.738 3.71841 9.03125 3.71841H15.9375V13.2809H9.03125C8.738 13.2809 8.5 13.5189 8.5 13.8122C8.5 14.1054 8.738 14.3434 9.03125 14.3434H16.4688C16.762 14.3434 17 14.1054 17 13.8122V3.18716C17 2.89391 16.762 2.65591 16.4688 2.65591Z" fill="#939393"/>
              <path d="M11.1562 4.78091H9.03125C8.738 4.78091 8.5 5.01891 8.5 5.31216C8.5 5.60541 8.738 5.84341 9.03125 5.84341H11.1562C11.4495 5.84341 11.6875 5.60541 11.6875 5.31216C11.6875 5.01891 11.4495 4.78091 11.1562 4.78091Z" fill="#939393"/>
              <path d="M11.1562 6.90591H9.03125C8.738 6.90591 8.5 7.14391 8.5 7.43716C8.5 7.73042 8.738 7.96842 9.03125 7.96842H11.1562C11.4495 7.96842 11.6875 7.73042 11.6875 7.43716C11.6875 7.14391 11.4495 6.90591 11.1562 6.90591Z" fill="#939393"/>
              <path d="M11.1562 9.03093H9.03125C8.738 9.03093 8.5 9.26893 8.5 9.56218C8.5 9.85543 8.738 10.0934 9.03125 10.0934H11.1562C11.4495 10.0934 11.6875 9.85543 11.6875 9.56218C11.6875 9.26893 11.4495 9.03093 11.1562 9.03093Z" fill="#939393"/>
              <path d="M11.1562 11.1559H9.03125C8.738 11.1559 8.5 11.3939 8.5 11.6872C8.5 11.9804 8.738 12.2184 9.03125 12.2184H11.1562C11.4495 12.2184 11.6875 11.9804 11.6875 11.6872C11.6875 11.3939 11.4495 11.1559 11.1562 11.1559Z" fill="#939393"/>
              <path d="M14.3438 4.78091H13.2812C12.988 4.78091 12.75 5.01891 12.75 5.31216C12.75 5.60541 12.988 5.84341 13.2812 5.84341H14.3438C14.637 5.84341 14.875 5.60541 14.875 5.31216C14.875 5.01891 14.637 4.78091 14.3438 4.78091Z" fill="#939393"/>
              <path d="M14.3438 6.90591H13.2812C12.988 6.90591 12.75 7.14391 12.75 7.43716C12.75 7.73042 12.988 7.96842 13.2812 7.96842H14.3438C14.637 7.96842 14.875 7.73042 14.875 7.43716C14.875 7.14391 14.637 6.90591 14.3438 6.90591Z" fill="#939393"/>
              <path d="M14.3438 9.03093H13.2812C12.988 9.03093 12.75 9.26893 12.75 9.56218C12.75 9.85543 12.988 10.0934 13.2812 10.0934H14.3438C14.637 10.0934 14.875 9.85543 14.875 9.56218C14.875 9.26893 14.637 9.03093 14.3438 9.03093Z" fill="#939393"/>
              <path d="M14.3438 11.1559H13.2812C12.988 11.1559 12.75 11.3939 12.75 11.6872C12.75 11.9804 12.988 12.2184 13.2812 12.2184H14.3438C14.637 12.2184 14.875 11.9804 14.875 11.6872C14.875 11.3939 14.637 11.1559 14.3438 11.1559Z" fill="#939393"/>
              <path d="M7.30666 10.2751L3.58791 6.0251C3.39241 5.80304 3.05773 5.78179 2.83779 5.97516C2.61679 6.16854 2.59448 6.50429 2.78785 6.72423L6.5066 10.9742C6.61179 11.0943 6.75841 11.1559 6.9061 11.1559C7.03042 11.1559 7.15473 11.1124 7.25673 11.0242C7.47773 10.8308 7.50004 10.4961 7.30666 10.2751Z" fill="#939393"/>
              <path d="M7.23282 5.4248C7.0012 5.24311 6.66757 5.28668 6.48695 5.51724L2.7682 10.2985C2.58863 10.5301 2.63007 10.8648 2.8617 11.0444C2.95945 11.1198 3.0742 11.1559 3.18788 11.1559C3.34513 11.1559 3.50238 11.0858 3.60651 10.9519L7.32526 6.17067C7.50588 5.93799 7.46445 5.60436 7.23282 5.4248Z" fill="#939393"/>
            </svg>
            <p>{{$t('monitor_export_excel')}}</p>
          </div>
          <div v-on:click="fetchActivityWord()">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="17" height="17" viewBox="0 0 17.000000 17.000000"
             preserveAspectRatio="xMidYMid meet" fill="none">
            <g transform="translate(0.000000,17.000000) scale(0.003761,-0.003761)"
            fill="#000000" stroke="none">
            <path d="M2370 4479 c-107 -21 -672 -128 -1254 -239 -583 -111 -1067 -205
            -1077 -211 -10 -5 -23 -18 -29 -29 -14 -26 -14 -3454 0 -3480 6 -11 19 -24 29
            -29 9 -6 269 -58 577 -116 307 -58 877 -167 1266 -241 389 -73 723 -134 742
            -134 22 0 45 9 63 24 l28 24 3 246 3 246 784 0 c529 0 793 3 812 11 34 13 80
            55 99 91 12 23 14 268 14 1618 0 1350 -2 1595 -14 1618 -19 36 -65 78 -99 91
            -19 8 -283 11 -812 11 l-784 0 -3 244 c-3 243 -3 245 -27 270 -33 35 -77 33
            -321 -15z m160 -2217 l0 -2059 -118 23 c-64 13 -567 109 -1117 213 -550 105
            -1024 196 -1053 202 l-52 10 0 1609 0 1609 52 10 c29 6 501 96 1048 201 876
            167 1229 235 1237 239 2 0 3 -925 3 -2057z m1710 -2 l0 -1530 -760 0 -760 0 0
            220 0 220 630 0 630 0 0 95 0 95 -630 0 -630 0 0 265 0 265 630 0 630 0 0 95
            0 95 -630 0 -630 0 0 225 0 225 630 0 630 0 0 95 0 95 -630 0 -630 0 0 220 0
            220 630 0 630 0 0 95 0 95 -630 0 -630 0 0 220 0 220 760 0 760 0 0 -1530z" fill="#939393"/>
            <path d="M414 3093 c3 -10 80 -385 171 -833 91 -448 168 -823 171 -832 5 -16
            22 -18 178 -18 l172 0 117 568 c64 312 118 578 120 592 2 16 5 20 9 10 3 -8
            56 -275 119 -593 l114 -577 169 2 170 3 168 815 c92 448 170 830 174 848 l6
            32 -156 0 c-86 0 -156 -2 -156 -5 0 -2 -43 -271 -95 -597 -52 -326 -95 -606
            -96 -623 -1 -16 -10 20 -20 80 -11 61 -64 342 -117 625 l-98 515 -164 0 -164
            0 -117 -555 c-65 -305 -125 -591 -133 -635 -9 -49 -14 -66 -15 -45 0 19 -44
            306 -98 638 l-98 602 -168 0 c-156 0 -168 -1 -163 -17z" fill="#939393"/>
            </g>
            </svg>
            <p>{{$t('export_word')}}</p>
          </div>
          <div v-on:click="fetchActivityMultimedia()">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M12.6603 7.88171C11.8711 8.25758 11.2276 9.39866 10.4748 8.64572C10.0174 8.14917 9.47465 7.35924 8.85103 6.46574C8.17676 5.50516 7.31714 4.67926 6.30573 5.62289C5.17658 6.68496 4.40099 9.56705 4.33374 11.0503L15.003 11.0539C14.7589 10.1824 13.8106 7.33399 12.6603 7.88171Z" fill="#939393"/>
              <path d="M12.6111 6.20816C13.3356 6.20816 13.923 5.6208 13.923 4.89626C13.923 4.17172 13.3356 3.58437 12.6111 3.58437C11.8866 3.58437 11.2992 4.17172 11.2992 4.89626C11.2992 5.6208 11.8866 6.20816 12.6111 6.20816Z" fill="#939393"/>
              <path d="M16.61 1.90213H2.7277C2.51245 1.90213 2.33766 2.07675 2.33766 2.292V4.26399H0.390041C0.174793 4.26416 0 4.43861 0 4.65386V14.7078C0 14.9232 0.174793 15.0977 0.390041 15.0977H14.2723C14.4876 15.0977 14.6623 14.9229 14.6623 14.7078V12.7362H16.6101C16.8252 12.7362 17.0002 12.5615 17.0002 12.3463V2.292C17 2.07692 16.825 1.90213 16.61 1.90213ZM13.6155 14.0512H1.04685V5.31102H2.33766V11.3878C2.14177 12.1445 2.02109 12.8717 1.99654 13.4127L12.666 13.4161C12.6191 13.2495 12.5462 13.0098 12.4512 12.7362H13.6155V14.0512ZM15.9531 11.6891H3.38451V2.94916H15.9531V11.6891Z" fill="#939393"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="17" height="17.0002" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <p>{{$t('download_multimedia')}}</p>
          </div>
          <div v-on:click="goToDownloadImages()">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M12.6603 7.88171C11.8711 8.25758 11.2276 9.39866 10.4748 8.64572C10.0174 8.14917 9.47465 7.35924 8.85103 6.46574C8.17676 5.50516 7.31714 4.67926 6.30573 5.62289C5.17658 6.68496 4.40099 9.56705 4.33374 11.0503L15.003 11.0539C14.7589 10.1824 13.8106 7.33399 12.6603 7.88171Z" fill="#939393"/>
              <path d="M12.6111 6.20816C13.3356 6.20816 13.923 5.6208 13.923 4.89626C13.923 4.17172 13.3356 3.58437 12.6111 3.58437C11.8866 3.58437 11.2992 4.17172 11.2992 4.89626C11.2992 5.6208 11.8866 6.20816 12.6111 6.20816Z" fill="#939393"/>
              <path d="M16.61 1.90213H2.7277C2.51245 1.90213 2.33766 2.07675 2.33766 2.292V4.26399H0.390041C0.174793 4.26416 0 4.43861 0 4.65386V14.7078C0 14.9232 0.174793 15.0977 0.390041 15.0977H14.2723C14.4876 15.0977 14.6623 14.9229 14.6623 14.7078V12.7362H16.6101C16.8252 12.7362 17.0002 12.5615 17.0002 12.3463V2.292C17 2.07692 16.825 1.90213 16.61 1.90213ZM13.6155 14.0512H1.04685V5.31102H2.33766V11.3878C2.14177 12.1445 2.02109 12.8717 1.99654 13.4127L12.666 13.4161C12.6191 13.2495 12.5462 13.0098 12.4512 12.7362H13.6155V14.0512ZM15.9531 11.6891H3.38451V2.94916H15.9531V11.6891Z" fill="#939393"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="17" height="17.0002" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <p>{{$t('download_page_header')}}</p>
          </div>
        </div>
    </div>
    <div class="ActivityResults--Stats">
      <div class="ActivityResults--Participants">
        <div class="ActivityResults--ParticipantsNum">
          <p class="numberBariol">{{result.participatedUsers > result.participants ? result.participants : result.participatedUsers }}/{{result.participants}}</p>
        </div>
        <div class="ActivityResults--ParticipantsProgress">
          <p>{{$t('participants')}}</p>
          <div class="ActivityResults--ProgressBar">
            <progress-bar :value="(result.participatedUsers/result.participants)*100" />
          </div>
        </div>
      </div>
      <Separator />
      <div class="ActivityResults--Counters">
        <div>
          <p class="numberBariol">{{result.commentsNum}}</p>
          <p>{{$t('comments')}}</p>
        </div>
        <span></span>
        <div>
          <p class="numberBariol">{{activity.newerCommentsNum}}</p>
          <p>{{$t('activity_results_new_comments')}}</p>
        </div>
        <span></span>
        <div>
          <p class="numberBariol">{{result.medalsNum}}</p>
          <p>{{$t('medals')}}</p>
        </div>
        <span></span>
        <div>
          <p class="numberBariol">{{result.notesNum}}</p>
          <p>{{$t('notes')}}</p>
        </div>
        <span></span>
        <div>
          <p class="numberBariol">{{result.tagsNum}}</p>
          <p>{{$t('tags_en')}}</p>
        </div>
        <span></span>
        <div>
          <p class="numberBariol">{{result.likes}}</p>
          <p>{{$t('likes')}}</p>
        </div>
        <span></span>
        <div>
          <p class="numberBariol">{{result.dislikes}}</p>
          <p>{{$t('dislikes')}}</p>
        </div>
      </div>
    </div>
    <div class="ActivityResults--Survey" v-if="result.type == 'EL_SURVEY'">
      <div>
        <h1>{{$t('results')}} <small><span :style="{ color: theme.mainColor}">({{result.answerReports.length}} {{$t('questions')}})</span></small></h1>
        <div class="ActivityResults__Answers">
          <div v-for="(answer, index) in result.answerReports" :key="index" class="answer">
            <div class="ActivityResults__Answers__Answer">
                <div class="ActivityResults__Answers__Answer__Counter">
                   <span :style="{ color: theme.mainColor}">{{$t('answers_of_total',{answersNum:answer.answeredMembers, total:answer.totalMembers})}}</span>
                </div>
                <div class="ActivityResults__Answers__Answer__Title">
                  <span :style="{ color: theme.mainColor}">{{index+1}}.</span> {{answer.question.questionText}}
                </div>
                <div v-if="answer.question.type == 'TEXT'">
                  {{$t('report_text_question_answers_alert')}}
                </div>
                <div class="ActivityResults__Answers__Answer__Option__List" v-if="answer.question.type =='SINGLE_SELECT' || answer.question.type=='MULTI_SELECT'">
                  <div v-for="(value, key, index) in answer.resultSummatory" :key="index" class="ActivityResults__Answers__Answer__Options">
                      {{key}} - {{value == 0 && answer.answeredMembers == 0 ? 0 : Math.round(((value/answer.answeredMembers)*100 + Number.EPSILON)*100) / 100}}%
                      <div class="answer__data__Progress__Bar" style="height:10px">
                        <progress-bar
                        :value="value == 0 && answer.answeredMembers == 0 ? 0 : (value/answer.answeredMembers)*100"
                        />
                      </div>
                  </div>
                </div>
                <div class="ActivityResults__Answers__Answer__Option__List" v-if="answer.question.type =='DIVIDE_POINTS'">
                  <div v-for="(value, key, index) in answer.pointsResult" :key="index" class="ActivityResults__Answers__Answer__Options">
                      {{key}}-{{value.name}} ({{value.average}} {{$t('average')}})
                      <div class="answer__data__Progress__Bar" style="height:10px">
                      <progress-bar
                      :value="value.total == 0 && answer.answeredMembers == 0 ? 0 : (value.average/answer.question.pointsNum)*100"
                      />
                      </div>
                  </div>
                </div>
                <div v-if="answer.question.type =='RANKING'">
                  <p v-html="$t('answers_average',{average:answer.resultAverage, total:answer.question.maxValue})"></p>
                  <div class="answer__data__Progress__Bar" style="height:10px">
                    <progress-bar
                     :value="((answer.resultAverage)/answer.question.maxValue)*100"
                     />
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ActivityResults--VideoChat" v-if="result.type == 'VIDEOCHAT' && videoUrl.length > 0">
      <h1>{{$t('activity_videochat_record')}}</h1>
      <div class="ActivityResults--VideoChatList">
        <div class="ActivityResults--VideoElement" v-for="(video, index) in videoUrl" :key="index" v-on:click="openVideochatCommentsList(video, index + 1)">
          <span>{{index+1}}</span>
          <i class="fas fa-play"></i>
        </div>
      </div>
    </div>
    <div class="ActivityResults--HeatMap" v-if="communityCurrentActivity.type == 'EL_HEATMAP'">
      <h1>{{$t('activity_results_heatmap')}}</h1>
      <HeatMapResults v-if="currentTab == 2" v-for="(img,index) of communityCurrentActivity.heatMapImages" ref="HeatMapResults" :answersNum="result.participatedUsers" :image="img" :heatMapAnswers="getHeatMapAnswersByIndex(index)" :heatmapPointDefinitions="getHeatMapPointsDefinitionByIndex(index)" :key="index" />
    </div>
    <div class="ActivityResults--Search" v-if="communityCurrentActivity.type != 'VIDEOCHAT'">
      <h1>{{$t('activity_search_comments')}}</h1>
      <CommunityTextInput :placeholder="$t('placeholder_write_text')" v-model="form.searchTxt"/>
      <div class="ActivityResults--Inputs">
        <Datepicker
          class="ActivityResults--datepicker"
          :placeholder="$t('date_from')"
          ref="dateFromPicker"
          v-model="form.dateFrom"
          format="dd-MM-yyyy HH:mm"
          :locale="communityInfo.language ? communityInfo.language : 'es'"
          :flow="['month','day','hours']">
          <template #input-icon>
              <activitySVG class="input-slot-image" :name="'input-calendar'"/>
          </template>
        </Datepicker>
        <Datepicker
          class="ActivityResults--datepicker"
          :placeholder="$t('date_to')"
          ref="dateToPicker"
          v-model="form.dateTo"
          format="dd-MM-yyyy HH:mm"
          :locale="communityInfo.language ? communityInfo.language : 'es'"
          :flow="['month','day','hours']">
          <template #input-icon>
              <activitySVG class="input-slot-image" :name="'input-calendar'"/>
          </template>
        </Datepicker>
        <!-- <DatePicker class="ActivityResults--datepicker" :placeholder="'Fecha desde'" ref="dateFromPicker" v-model="form.dateFrom" :lang="communityInfo.language ? communityInfo.language : 'es'" format="DD-MM-YYYY HH:mm" :time-picker-options="{ start: '00:00', step: '00:30', end: '23:59' }" type="datetime">
          <i slot="calendar-icon"><activitySVG :name="'input-calendar'"/></i>
        </DatePicker> -->
        <!-- <DatePicker class="ActivityResults--datepicker" :placeholder="'Fecha hasta'" ref="dateToPicker" v-model="form.dateTo" :lang="communityInfo.language ? communityInfo.language : 'es'" format="DD-MM-YYYY HH:mm" :time-picker-options="{ start: '00:00', step: '00:30', end: '23:59' }" type="datetime">
          <i slot="calendar-icon"><activitySVG :name="'input-calendar'"/></i>
        </DatePicker> -->
      </div>
      <div class="ActivityResults--Inputs">
        <select name="" v-model="form.userId">
          <option value="null">{{$t('activity_results_find_by_users')}}</option>
          <option :style="m.status === 'DELETED' ? {'text-decoration': 'line-through', 'color': 'gray'} : {}" :value="m.identifier"  :key="m.id" v-for="m in sortedMembers">{{m.nickname}}
        </option>
        </select>
        <select name="" v-model="form.tag">
          <option value="null">{{$t('activity_results_find_by_tags')}}</option>
          <option :value="tag" :key="tag.id" v-for="tag in communityTags">{{tag}}</option>
        </select>
      </div>
      <CheckBox class="ActivityResults--CheckBox" :text="$t('comments_with_medals')" v-model="form.onlyWithMedal"/>
      <div class="ActivityResults--Actions">
        <CommunityButton v-show="comments.length > 0" v-on:click="wordDownload()">{{$t('export_word')}}</CommunityButton>
        <CommunityButton v-on:click="searchComments()">{{$t('activity_search_comments')}}</CommunityButton>
      </div>
      <div class="ActivityResults--Searched">
        <h4 v-if="comments.length > 0">{{$t('activity_comments_found')}}</h4>
        <CommentsList
          v-if="comments.length > 0"
          :comments="comments"
          :theme="theme"
          :allowReply="false"
          :allowLike="false"
          :allowDislike="false"
          :allowAddMedals="true"
          :hideGamification="communityInfo.hideGamification"
          v-on:comment-medal="$emit('comment-medal',$event)"
          />
          <Pagination
          v-if="pages > 1"
          v-model="page"
          :records="count"
          :per-page="pageSize"
          @paginate="searchComments"/>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { inject, onMounted } from "vue";
import {mount} from '@/utils'
import vueApp from '@/main';
import { mapGetters } from 'vuex';
import FileSaver from 'file-saver';
import ActivityImage from '@/components/Activity/ActivityImage.vue'
import ActivityStatus from '@/components/Activity/ActivityStatus.vue'
import Separator from '@/components/Separator.vue'
import ProgressBar from '@/components/ProgressBar.vue';
import CommunityTextInput from '@/components/CommunityTextInput.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Pagination from 'v-pagination-3';
import activitySVG from '~/components/ActivitySVG/activitySVG.vue'
import CheckBox from '@/components/CheckBox'
import CommunityButton from '@/components/CommunityButton'
import CommentsList from '~/components/CommentsList'
import HeatMapResults from '~/components/HeatMap/HeatMapResults'
import VideochatCommentsList from '@/components/Videochat/VideochatCommentsList.vue'
import store from '@/store';
import {
  COMMUNITY_FETCH_ACTIVITY_RESULTS,
  COMMUNITY_FETCH_ACTIVITY_EXCEL,
  COMMUNITY_FETCH_ACTIVITY_WORD,
  COMMUNITY_FETCH_ACTIVITY_MULTIMEDIA,
  COMMUNITY_FETCH_COMMENTS_SEARCH_V2,
  COMMUNITY_FETCH_COMMENTS_SEARCH_WORD
} from '@/store/community/activities.module';
export default {
  name:'ActivityResults',
  computed: {
    ...mapGetters([
      'communityInfo',
      'communityActivities',
      'communityCurrentActivityId',
      'communityCurrentActivity',
      'communityTags',
    ])
  },
  components:{
    ActivityImage,
    ActivityStatus,
    Separator,
    ProgressBar,
    CommunityTextInput,
    Datepicker,
    activitySVG,
    CheckBox,
    CommunityButton,
    CommentsList,
    Pagination,
    HeatMapResults,
    VideochatCommentsList
  },
  watch:{
    communityCurrentActivityId(oldValue, newValue){
      this.comments = []
      this.page = 0
      this.pageSize = 0
      this.pages = 0
    },
    members(oldValue, newValue) {
        if (newValue) {
            this.sortedMembers = [...newValue].sort((a, b) => 
                a.nickname.localeCompare(b.nickname, 'es', { numeric: false, sensitivity: "variant" })
            );
            console.log("this.sortedMembers:", this.sortedMembers);
        }
    }
  },
  props: {
    activity:Object,
    result:Object,
    videoUrl:Array,
    members:Array,
    heatMapAnswers:Object,
    currentTab:Number,
  },
  data(){
    return{
      form:{
          searchTxt:'',
          activityId:null,
          userId:null,
          dateFrom:'',
          dateTo:'',
          tag:null,
          onlyWithMedal:false,
      },
      count:0,
      pages:0,
      page:1,
      pageSize:10,
      comments:[],
      instanceModal: [],
      sortedMembers:[]
    }
  },
  async created(){
    jQuery(document).ready(function(){
       jQuery('body').on('contextmenu', 'video', function() { return false; });
    });
  },
  methods:{
    openVideochatCommentsList(video, index){
      const { el, vNode, destroy } = mount(VideochatCommentsList, {  props: { comments: video.comments, url: video.url, videoName: ""+index+"" },app: vueApp})
      vueApp._container.appendChild(el);
      this.instanceModal = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
    },
    getHeatMapAnswersByIndex(index){
      let pointsByImageIndex = _.filter(this.heatMapAnswers.heatmapPoints, function (p) { return p.imageIndex == index });
      let data = {
        hmvaluesByType:{
          RED: _.filter(pointsByImageIndex, function (p) { return p.type == "RED" }),
          GREEN:_.filter(pointsByImageIndex, function (p) { return p.type == "GREEN" }),
        },
        hmvaluesByPoint:{}
      }
      let pointsByIndex = {}
      let pointIndex = 0
      for(let point of pointsByImageIndex){
        if (pointsByIndex[point.identifier] == undefined) pointsByIndex[point.identifier] = []
        pointsByIndex[point.identifier].push(point)
      }
      data.hmvaluesByPoint = pointsByIndex
      return data
    },
    getHeatMapPointsDefinitionByIndex(index){
      return _.filter(this.communityCurrentActivity.heatmapPointDefinitions, function (p) { return p.imageIndex == index });
    },
    fetchActivityExcel: async function(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(COMMUNITY_FETCH_ACTIVITY_EXCEL,{activityId:vm.communityCurrentActivityId})
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              FileSaver.saveAs(file,vm.$t('activity')+" "+vm.communityCurrentActivityId+".xlsx");
            })
          })();
        }
      })
    },
    fetchActivityWord: async function(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(COMMUNITY_FETCH_ACTIVITY_WORD,{activityId:vm.communityCurrentActivityId})
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
              FileSaver.saveAs(file,vm.$t('activity')+" "+vm.communityCurrentActivityId+".docx");
            })
          })();
        }
      })
    },
    goToDownloadImages(){
      this.$router.push({name:'imageDownloader'})
    },
    fetchActivityMultimedia: async function(){
      Swal.fire({
        icon: 'info',
        text: this.$t('msg_multimedia_download_info'),
        confirmButtonText: this.$t('action_accept')
      });
    },
    async searchComments(){
      this.form.activityId = this.$route.params.id
      let page = this.page
      if(this.page != 0) page--
      await store.dispatch(COMMUNITY_FETCH_COMMENTS_SEARCH_V2,{data:this.form,page:page,pageSize:this.pageSize})
      .then((data) => {
        for(let c of data.objects ){
          if(c!=undefined && c.text.includes(this.form.searchTxt)){
            c.text = c.text.split(this.form.searchTxt).join("<span style='background:var(--primary-color);color:white'>"+this.form.searchTxt+"</span>")
          }
         c.allowAddMedals = true;
        }
        this.count = data.count
        this.pages = data.pages
        this.comments = data.objects
        if(this.page > 1 && data.pages<this.page) {
          this.page = data.pages
          this.searchComments()
        }
      });
    },
    async wordDownload(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: "Se va a descargar el archivo",
        text: vm.$t('msg_file_download'),
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            let page = vm.page
            if(vm.page != 0) page--
            let data = vm.form
            data.page = page
            data.pageSize = vm.pageSize
            await store.dispatch(COMMUNITY_FETCH_COMMENTS_SEARCH_WORD,data)
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
              FileSaver.saveAs(file,vm.$t('comments_of_community',{community:vm.origin})+".docx");
            });
          })();
        }
      })
    },
  },
  mounted(){
    const emitter = inject("emitter")
    emitter.on("close-videochat-comments", (event) => {
      console.log(this.instanceModal);
      this.instanceModal.destroy()
      this.instanceModal = null
    })
  
  }
}
</script>
<style lang="scss">
.ActivityResults{
  &--datepicker{
    &:first-of-type{
      margin-right: 10px;
    }
    input{
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
      font-size: 16px;
      height: 36px;
      color: #404040;
      font-weight: bold;
      border-radius: 0px;
      padding-left: 12px;
      &::placeholder{
        color: #404040;
        font-weight: bold;
        opacity: 1;
      }
    }
    .dp__input_icon{
      right: 5px;
    left: auto;
    }
  }
}
</style>
<style scoped lang="scss">
@import url('https://fonts.cdnfonts.com/css/bariol-regular-2?styles=29982');
.numberBariol{
  font-weight: bold;
  font-family:"Bariol Regular";
  letter-spacing: -0.01em;
  color: var(--primary-color-text);
}
.ActivityResults{
  &--Header{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E0E0E0;
    @media only screen and (max-width: 600px) {
    padding: 20px 0px;
  }
  }
  &--Title{
    display: flex;
    margin-bottom: 10px;
    h1{
      font-size: 25px;
      letter-spacing: -0.01em;
      color: #4F4F4F;
    }
    .activityLogo{
      margin:0;
      margin-right: 10px;
    }
  }
  &--Info{
    display: flex;
    justify-content: space-between;
    margin-left: 38px;
  }
  &--Status{
    display: flex;
    align-items: center;
    p{
      font-size: 14px;
      color: #4F4F4F;
      text-transform: uppercase;
    }
    span{
      border-right: 1px solid #4F4F4F;
      margin:0 10px;
      height: 11px;
    }
  }
  &--Downloads{
    display: flex;
    div{
      margin-right: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg{
        margin-right:5px;
      }
      p{
        text-transform: uppercase;
        font-size: 11px;
        color: #939393;
      }
    }
  }
  &--VideoChat{
    padding: 16px 30px;
    border-bottom: 1px solid #E0E0E0;
    h1{
      font-size: 22px;
      letter-spacing: -0.01em;
      color: #4F4F4F;
    }
  }
  &--VideoChatList{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
  }
  &--VideoElement{
    width: 240px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    border: 2px solid #FF4149;
    border-radius: 5px;
    cursor: pointer;
    background: conic-gradient(from 180deg at 50% 50%, #FAFAFA 0deg, #EBF5FF 63.75deg, #F8F9FE 180deg, #FBF5FF 296.25deg, #FAFAFA 360deg), #FFFFFF;
    span{
      font-size: 25px;
      margin-right: 10px;
      font-weight: 900;
      font-family: 'Bariol Regular';
    }
    i{
      font-size: 35px;
      margin-right: 10px;
      color: #FF4149;
    }
    &:hover{
      border: 2px solid #A2DE62;
      i{
        color:#A2DE62;
      }
    }
  }
  &--HeatMap{
    padding: 16px 30px;
    border-bottom: 1px solid #E0E0E0;
    h1{
      font-size: 22px;
      letter-spacing: -0.01em;
      color: #4F4F4F;
      margin-bottom: 10px;
    }
  }
  &--Survey{
    padding: 16px 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E0E0E0;
    .dashboardLink{
      margin-top: 5px;
      font-size: 16px;
      background: var(--accent-color)!important;
      a{
        color: white;
      }
      i{
        margin-right: 5px;
        color: white;
      }
    }
    h1{
      font-size: 22px;
      letter-spacing: -0.01em;
      color: #4F4F4F;
    }
  }
  &--Stats{
    padding: 16px 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E0E0E0;
    @media only screen and (max-width: 600px) {
      padding: 16px 2px;
    
  }
  }
  &--Participants{
    display: flex;
    align-items: center;
  }
  &--ParticipantsNum{
    font-size: 34px;
    margin-right: 10px;
  }
  &--ParticipantsProgress{
    width:100%;
    p{
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #333333;
    }
  }
  &--ProgressBar{
    height: 11px;
  }
  &--Counters{
    display: flex;
    flex-wrap: wrap;
    span{
      border-right:1px solid #BDBDBD;
      width:1px;
      height: auto;
      margin: 0 10px;
    }
    div{
      display: flex;
      max-width: 100px;
    }
    p:first-child{
      font-size: 30px;
      margin-right: 8px;
      line-height: 23px;
    }
    p:last-child{
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #333333;
    }
  }
  &--Search{
    padding: 27px 30px;
    h1{
      font-size: 22px;
      letter-spacing: -0.01em;
      color: #4F4F4F;
    }
    .TextInput{
      width: 100%;
      padding: 0px;
    }
  }
  &--Inputs{
    display: flex;
    margin-top: 10px;
    select{
      width:50%;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
      font-size: 16px;
      height: 36px;
      outline: none;
      padding: 0 10px;
      color: #404040;
      font-weight: bold;
      &:first-of-type{
        margin-right: 10px;
      }
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      select{
        width: 100%;
      }
  }
  }
  &--CheckBox{
    margin-top: 20px;
  }
  &--Actions{
    display: flex;
    justify-content: flex-end;
    button{
      font-size: 16px;
      background: var(--accent-color)!important;
      margin-top: 20px;
      margin-left:10px;
    }
  }
  &__Answers{
    font-size: 0.9em;
    &__Answer{
      margin-top:10px;
      border-top: 1px solid #e9e9e9;
      padding-top: 15px;
      padding-bottom: 15px;
      &__Title{
        font-weight: 600;
        margin-bottom: 10px;
      }
      &__Option__List{
        margin-left: 20px;
      }
      &__Options{
        font-size: 0.95em;
        margin-bottom: 5px;
      }
      &__Counter{
        float: right;
      }
    }
  }
}

</style>
